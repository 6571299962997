import * as Realm from "realm-web";
import CONFIG from "../../env/env.json";

export const app = new Realm.App({ id: CONFIG.ATLAS.APP_ID });

export const {
  BSON: { ObjectId },
} = Realm;
export const getDBInstance = () => {
  if (!app || !app.currentUser) {
    return;
  }
  return app.currentUser.mongoClient(CONFIG.DB.SERVICE).db(CONFIG.DB.NAME);
};

export const addFloodData = async ({ data }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!data) {
    return;
  }
  const result = await app.currentUser.callFunction("floodDataPost", data);
  return result;
};

export const updateFloodData = async ({
  id = null,
  comments = null,
  status = null,
  modifiedBy = null,
  phone = null,
}: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }

  const result: any = await app.currentUser.callFunction("floodDataPut", { id, comments, status, modifiedBy, phone });
  return result;
};

export const getOneFloodData = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  const result = await app.currentUser.callFunction("floodDataGet", { id });
  return result;
};

export const getFloodData = async ({ page = 0, rowsPerPage = 10 }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const result: any = await app.currentUser.callFunction("floodDataList", { query: { status: 'open' }, skip, limit, sort: { "metaData.createdAt": -1 } });
  return { items: result.items || [], totalCount: result.totalCount || 0 };
};

export const getGeoCode = async ({ data }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!data) {
    return;
  }
  const result = await app.currentUser.callFunction("maps", {
    functionName: "geocode",
    data,
  });
  return result;
};

// export const updateFirebaseToken = async (token: any) => {
//   if (!app || !app.currentUser) {
//     return;
//   }
//   if (!token) {
//     return;
//   }
//   const result = await app.currentUser.callFunction("updateFirebaseToken", {
//     fcmToken: token,
//   });
//   return result;
// };
