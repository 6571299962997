import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { onMessage } from "firebase/messaging";
// import { initFirebaseMessaging, firebaseMessaging,} from "controller/services/firebaseService";
import { UserContext } from "controller/context/userContext";

const App = () => {
  const queryClient = new QueryClient();
  
  const { user, fetchUser } = React.useContext(UserContext);
  fetchUser();

  const AuthRedirect = React.lazy(() => import("./views/auth/AuthRedirect"));
  const RequestForm = React.lazy(() => import("./views/request"));
  const Footer = React.lazy(() => import("./components/footer/Footer"));
  const Header = React.lazy(() => import("./components/header/Header"));
  const Home = React.lazy(() => import("./views/home/home"));
  const Details = React.lazy(() => import("./views/details/details"));
  const Cms = React.lazy(() => import("./views/cms/cms"));


  // React.useEffect(() => {
  //   if (user && user.providerType === "oauth2-google") {
  //     if (window.Notification) {
  //       initFirebaseMessaging();
  //       onMessage(firebaseMessaging, (payload) => {
  //         // console.log("Message received. ", payload);
  //       });
  //     }
  //   }
  // }, [user]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <React.Suspense fallback={<>...</>}>
          <Header />
        </React.Suspense>
        <Routes>
          <Route
            path="/auth/redirect"
            element={
              <React.Suspense fallback={<>...</>}>
                <AuthRedirect />
              </React.Suspense>
            }
          />
          <Route
            path="/request"
            element={
              <React.Suspense fallback={<>...</>}>
                <RequestForm />
              </React.Suspense>
            }
          />
          <Route
            path="/page/:pageId"
            element={
              <React.Suspense fallback={<>...</>}>
                <Cms />
              </React.Suspense>
            }
          />
          <Route
            path="/details/:id"
            element={
              user && user.providerType === "oauth2-google" ? (
                <React.Suspense fallback={<>...</>}>
                  <Details />
                </React.Suspense>
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/"
            element={
              <React.Suspense fallback={<>...</>}>
                <Home />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <React.Suspense fallback={<>...</>}>
          <Footer />
        </React.Suspense>
      </QueryClientProvider>
    </>
  );
};

export default App;
