import * as Realm from "realm-web";
import { createContext, useState, useEffect } from "react";
import { app } from "../services/dbServices";

export const UserContext = createContext(null);
export function UserProvider({ children }: any) {
  const [user, setUser] = useState<any>(null);
  const [customerData, setCustomerData] = useState();

  const googleLogin = async () => {
    // await logOutUser();
    const credentials = Realm.Credentials.google({
      redirectUrl: `${window.location.origin}/auth/redirect`,
    });
    const authUser = await app.logIn(credentials);
    setUser(authUser);
    return authUser;
  };

  const guestLogin = async () => {
    await logOutUser();
    const credentials = Realm.Credentials.anonymous();
    const authUser = await app.logIn(credentials);
    setUser(authUser);
    return authUser;
  };

  useEffect(() => {
    if (!user && app.currentUser) {
      setUser(app.currentUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUser = async () => {
    if (!app.currentUser) return false;
    try {
      await app.currentUser.refreshCustomData();
      setUser(app.currentUser);
      return app.currentUser;
    } catch (error) {
      throw error;
    }
  };

  const logOutUser = async () => {
    if (!app.currentUser) {
      setUser(null);
      return true;
    }
    try {
      await app.currentUser.logOut();
      window.localStorage.clear();
      setUser(null);
      return true;
    } catch (error) {
      throw error;
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        fetchUser,
        googleLogin,
        guestLogin,
        logOutUser,
        customerData,
        setCustomerData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
